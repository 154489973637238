import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { PanInfo, useDragControls } from 'framer-motion';
import { Button, Toolbar } from 'react95';
import {
  CloseButton,
  StyledActions,
  StyledIcon,
  StyledMobileActionsWrapper,
  StyledMotionWrapper,
  StyledTitleContainer,
  StyledWindow,
  StyledWindowContent,
  StyledWindowHeader,
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/root';
import { setPosition } from '../../store/reducers/apps/appWindowSlice';
import { useScreen } from '../../contexts/ScreenContext';
import { Tooltip } from '../ui';

type Props = {
  children: ReactElement | ReactElement[] | string;
  iconScr?: string;
  className?: string;
  onClose: () => void;
  title: string;
  fulldesk?: boolean;
  footer?: ReactElement | ReactElement[] | string;
  minWidth?: number;
  minHeight?: number;
  mobileActions?: ReactElement | ReactElement[] | string;
  x?: number;
  y?: number;
  isdraggable?: boolean;
  disableScale?: boolean;
  closeTooltipText?: { title: string; content: string };
};

const AppWindow: FC<Props> = ({
  fulldesk,
  children,
  title,
  iconScr,
  onClose,
  footer,
  className,
  mobileActions,
  minWidth = 0,
  minHeight = 0,
  x,
  y,
  isdraggable = true,
  disableScale = false,
  closeTooltipText = undefined,
}) => {
  const dispatch = useDispatch();
  const position = useSelector((state: RootState) => state.windows.position);
  const controls = useDragControls();
  const { width: screenWidth, height: screenHeight, scale } = useScreen();
  const windowRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const isMobile = screenWidth <= 767;

  const calculateInitialPosition = useCallback(
    (offsetWidth: number, offsetHeight: number) => {
      const initialX =
        x !== undefined ? Math.max(0, Math.min(x, screenWidth - offsetWidth)) : position.x;
      const initialY =
        y !== undefined ? Math.max(0, Math.min(y, screenHeight - offsetHeight)) : position.y;
      return { initialX, initialY };
    },
    [x, y, screenWidth, screenHeight, position.x, position.y],
  );

  useLayoutEffect(() => {
    if (windowRef.current) {
      const { offsetWidth, offsetHeight } = windowRef.current;
      const { initialX, initialY } = calculateInitialPosition(offsetWidth, offsetHeight);

      if (initialX !== position.x || initialY !== position.y) {
        dispatch(setPosition({ x: initialX, y: initialY }));
      }
    }
  }, [calculateInitialPosition, dispatch, position.x, position.y]);
  useEffect(() => {
    if (closeTooltipText) {
      setTimeout(() => {
        setShowTooltip(true);
      }, 1000);
    }
  }, [closeTooltipText]);
  const handleDragStart = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      if (isdraggable) {
        controls.start(event, { snapToCursor: false });
      }
    },
    [isdraggable, controls],
  );

  const handleDragEnd = useCallback(
    (e: PointerEvent, info: PanInfo) => {
      if (windowRef.current) {
        const { offsetWidth, offsetHeight } = windowRef.current;
        const newPosition = {
          x: Math.max(0, Math.min(position.x + info.offset.x, screenWidth - offsetWidth)),
          y: Math.max(0, Math.min(position.y + info.offset.y, screenHeight - offsetHeight)),
        };

        if (newPosition.x !== position.x || newPosition.y !== position.y) {
          dispatch(setPosition(newPosition));
        }
      }
    },
    [dispatch, position.x, position.y, screenWidth, screenHeight],
  );

  const getDragConstraints = useCallback(() => {
    if (windowRef.current) {
      const { offsetWidth, offsetHeight } = windowRef.current;
      const scaledWidth = offsetWidth / scale;
      const scaledHeight = offsetHeight / scale;
      const left = scale > 1.3 ? -50 : 10;
      const top = scale > 1.3 ? -50 : 10;
      const right = screenWidth - scaledWidth;
      const bottom = screenHeight - scaledHeight;
      return {
        left,
        top,
        right: Math.max(right, left),
        bottom: Math.max(bottom, top),
      };
    }
    return { left: 0, top: 0, right: 0, bottom: 0 };
  }, [scale, screenWidth, screenHeight]);

  const motionDivProps = isMobile
    ? {}
    : {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        dragElastic: 0.5,
        dragMomentum: false,
        drag: true,
        dragListener: false,
        dragControls: controls,
        onDragEnd: handleDragEnd,
        dragConstraints: getDragConstraints(),
        style: { x: position.x, y: position.y },
      };
  const calculateScale = () => {
    if (scale === 1) {
      return 1 / scale;
    } else if (scale <= 1.5) {
      return 1.2 / scale;
    } else if (scale <= 2) {
      return 1.15 / scale;
    }
    return 1;
  };

  return (
    <StyledMotionWrapper
      isDraggable={isdraggable}
      className={isMobile ? 'mobile' : ''}
      {...motionDivProps}
    >
      <StyledWindow
        fulldesk={fulldesk}
        className={className}
        ref={windowRef}
        style={{
          transform: isMobile || disableScale ? 'none' : `scale(${calculateScale()})`,
        }}
      >
        <StyledWindowHeader style={{ touchAction: 'none' }} onPointerDown={handleDragStart}>
          <StyledTitleContainer>
            {iconScr && <StyledIcon src={iconScr} />}
            {title}
          </StyledTitleContainer>
          <StyledActions>
            <StyledMobileActionsWrapper>{mobileActions}</StyledMobileActionsWrapper>
            <Button onClick={onClose}>
              {closeTooltipText ? (
                <>
                  <Tooltip
                    visible={showTooltip}
                    onClose={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowTooltip(false);
                    }}
                    title={closeTooltipText.title}
                    content={closeTooltipText.content}
                  />
                  <CloseButton />
                </>
              ) : (
                <CloseButton />
              )}
            </Button>
          </StyledActions>
        </StyledWindowHeader>
        <StyledWindowContent minWidth={minWidth} minHeight={minHeight} footer={footer}>
          {children}
        </StyledWindowContent>
        {footer && <Toolbar style={{ padding: 0 }}>{footer}</Toolbar>}
      </StyledWindow>
    </StyledMotionWrapper>
  );
};

export default React.memo(AppWindow);

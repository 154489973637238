import React from 'react';
import { TooltipContainer, CloseButton, TextContainer, Title } from './styled';

interface TooltipProps {
  visible: boolean;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  content?: string;
  title?: string;
}

const Tooltip: React.FC<TooltipProps> = ({ visible, onClose, content, title }) => {
  return (
    <TooltipContainer visible={visible}>
      <Title>
        {title}
        <CloseButton onClick={onClose}>×</CloseButton>
      </Title>
      <TextContainer>{content}</TextContainer>
    </TooltipContainer>
  );
};

export default Tooltip;

import styled from 'styled-components';

export const StyledWindow = styled.div`
  width: 100%;
  display: flex;
  overflow-y: auto;
  font-family: 'fsex300', monospace;
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    max-height: none;
  }
`;

export const LeftPane = styled.div`
  color: #000;
  width: 50%;
  display: flex;
  padding: 5px;
  @media (max-width: 768px) {
    width: 97%;
    height: 52%;
  }
`;

export const RightPane = styled.div`
  padding: 5px;
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 768px) {
    flex: 1;
  }
`;

import React from 'react';
import { LeftPane, RightPane, StyledWindow } from './styled';
import { RightPaneSection, Slider } from './components';
import { AppWindow } from '../AppWindow';

type Props = { onClose: () => void; x?: number; y?: number };

const MainContainer: React.FC<Props> = ({ onClose, x, y }) => {
  return (
    <AppWindow
      title={'Welcome to mudume'}
      iconScr={'android-chrome-512x512.png'}
      closeTooltipText={{ title: 'Click me to proceed to the website', content: '' }}
      {...{ onClose, x, y }}
    >
      <StyledWindow>
        <LeftPane>
          <Slider />
        </LeftPane>
        <RightPane>
          <RightPaneSection />
        </RightPane>
      </StyledWindow>
    </AppWindow>
  );
};

export default MainContainer;

import styled from 'styled-components';
import { Window, WindowContent, WindowHeader } from 'react95';
import { Icon } from '../ui/Icon';
import { ReactElement } from 'react';
import { motion } from 'framer-motion';

type IWindow = {
  fulldesk?: boolean;
  isDraggable?: boolean;
};

export const StyledMotionWrapper = styled(motion.div)<IWindow>`
  position: absolute;
  touch-action: none;
`;

export const StyledWindowContent = styled(WindowContent)<{
  footer?: ReactElement | ReactElement[] | string;
  minWidth: number;
  minHeight: number;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ footer }) => (footer ? 'calc(100% - 64px)' : 'calc(100% - 40px)')};
  min-width: ${({ minWidth }) => minWidth && minWidth + 'px'};
  min-height: ${({ minHeight }) => minHeight && minHeight + 'px'};
  padding: 0;
  @media (max-width: 768px) {
    position: relative;
    width: 100%;
    height: calc(100% - 48px);
    min-height: initial;
    min-width: initial;
    left: initial;
    top: initial;
  }
`;

export const CloseButton = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: -1px;
  margin-top: -1px;
  transform: rotateZ(45deg);
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.materialText};
  }

  &:before {
    height: 100%;
    width: 3px;
    left: 50%;
    transform: translateX(-50%);
  }

  &:after {
    height: 3px;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const StyledIcon = styled(Icon)`
  width: 100%;
  max-width: 24px;
  height: auto;
  margin: 0 8px 0 4px;
`;

export const StyledWindowHeader = styled(WindowHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: grab;
  user-select: none;
`;

export const StyledTitleContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
`;

export const StyledWindow = styled(Window)<IWindow>`
  position: ${({ fulldesk }) => (fulldesk ? 'fixed' : 'absolute')};
  width: ${({ fulldesk }) => (fulldesk ? '100vw' : '725px')};
  height: ${({ fulldesk }) => (fulldesk ? 'calc(100vh - 46px)' : '600px')};
  overflow: hidden;
  @media (max-width: 768px) {
    position: fixed;
    width: 100%;
    height: calc(100vh - 46px);
    left: initial;
    top: initial;
    overflow-y: scroll;
  }
`;

export const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 3px;
`;

export const StyledMobileActionsWrapper = styled.span`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;
